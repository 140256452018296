import moment from "moment";
import * as _ from "lodash";

export const getUserName = (data: any) => {
  const { firstName = "-", lastName = "-" } = data;
  return `${firstName} ${lastName}`;
};

export const getUTCDate = (date: any) => {
  return new Date(new Date(date).toUTCString().replace("GMT", ""));
};

export const expiryDate = (date_string: string, creationDate: string) => {
  if (!date_string || !moment(date_string).format("YYYY-MM-DD")) {
    console.log("Invalid expiry date:", date_string);
    return;
  }

  let expiration = moment(date_string).format("YYYY-MM-DD");
  let current_date = moment(new Date().toUTCString()).format("YYYY-MM-DD");
  let days: any = 0;
  if (expiration < current_date) {
    days = 0;
  } else {
    days = Number.isNaN(moment(expiration).diff(current_date, "days"))
      ? 0
      : moment(expiration).diff(current_date, "days");
  }
  return days;
};
